import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom/dist';

import Header from './components/Header'
import Footer from './components/Footer'
import axios from 'axios';

const Home = () => {

    const [language, setLanguage] = useState("de");
    const { eventId } = useParams();
    const [selectedEvent, setSelectedEvent] = useState(0);

    let navigate = useNavigate();

    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        requestFormSettings();
    }, [])

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            if (eventId == 200624) {
                setSelectedEvent(res.data.eventId1);
            } else if (eventId == 270624) {
                setSelectedEvent(res.data.eventId2);
            } else if (eventId == "070924") {
                setSelectedEvent(res.data.eventId3);
            }
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const onSubmit = () => {
        sessionStorage.setItem("selectedEventId", selectedEvent);
        if (selectedEvent == 190322) {
            navigate(`/${language}/event`)
        } else {
            navigate(`/${language}/login/${selectedEvent}`)
        }
    }

    return (
        <div className='container page-container'>
            <Header />
            <div className='text-center'>
                <p className='fw-bold fs-2 mb-1'>{resources?.Welcome1}</p>
                {eventId == "070924" ? <p className='fw-bold fs-2 mb-1'>{resources?.Welcome2Mitarbeiter}</p> : <p className='fw-bold fs-2 mb-1'>{resources?.Welcome2}</p>}
                {eventId == "070924" && <p className='fw-bold fs-2 mb-5'>{resources?.Welcome3}</p>}
                {eventId == "070924" ? <p className='fs-6'>{resources?.SelectLanguageMitarbeiter}</p> : <p className='fs-6'>{resources?.SelectLanguage}</p>}
                <div className='center'>
                    <select className='form-select select mb-4' value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value="de">Deutsch</option>
                        <option value="fr">Français</option>
                        <option value="it">Italiano</option>
                        <option value="en">English</option>
                    </select>
                    <button className='button btn mb-5' onClick={onSubmit}>{resources?.Ok}</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home