import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom/dist'
import axios from 'axios'
import { api, getAddressOfOrder } from 'ticketino-api-client'

import Header from './components/Header'
import Footer from './components/Footer'

const defaultAddress = {
    firstName: "",
    name: "",
    company: "",
    email: "",
    confirmEmail: "",
    code1: "",
    code2: "",
    code3: "",
    code5: "",
    code6: "",
    code7: "",
    mobile: "",
    countryId: 176
}

const Address = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [appliedPromotion] = useState(sessionStorage.getItem("appliedPromotion"));
    const [eventId] = useState(sessionStorage.getItem("selectedEventId") ?? 0);

    const [isFirstEvent, setIsFirstEvent] = useState();

    const [address, setAddress] = useState({ ...defaultAddress });

    const { language } = useParams()

    let languageId = 0;

    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    let navigate = useNavigate();

    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId && appliedPromotion?.length > 0) {
            loadAddress(orderId, appliedPromotion);
        }
    }, [orderId, appliedPromotion]);

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestFormSettings = async () => {
        try {
            const response = await axios.get(`form/formsettings`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const loadAddress = async (orderId, code) => {
        try {
            const updatedOrder = await getOrderByOrderId(orderId);
            const settings = await requestFormSettings();
            let updatedAddress = {};

            try {
                updatedAddress = await getAddressOfPromotionCode(code, settings.organizerId);
            } catch { }

            let selectedTicketType = updatedOrder?.tickets[0]?.ticketTypeId;

            if (settings.ticketTypeId1 == selectedTicketType) {
                setIsFirstEvent(true);
            }

            if (settings.ticketTypeId2 == selectedTicketType) {
                setIsFirstEvent(false);
            }

            if (updatedAddress) {
                setAddress({
                    ...address,
                    firstName: updatedAddress.firstName,
                    name: updatedAddress.name,
                    company: updatedAddress.company,
                    email: updatedAddress.email,
                    code5: "ANMELDUNG"
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getAddressOfPromotionCode = async (promotionCode, organizerId) => {
        try {
            const res = await axios.get(`${baseUrl}/PromotionCode/${promotionCode}/Address?organizerId=${organizerId}`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const addAddressToBasket = async () => {
        let addressBody = { ...address };
        addressBody.code5 = "ANMELDUNG"

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error)
        }
    };

    const changePaymentTypeToFree = async () => {
        await axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(async () => {
                await confirmShopbasketByOrderId(orderId);
            });
    }

    const onSubmit = async () => {
        await addAddressToBasket();
        await changePaymentTypeToFree(orderId);
        navigate(`/${language}/confirmation/${orderId}`)
    }

    const onInputChange = (e) => {
        setAddress({ ...address, [e.target.name]: e.target.value });
    }

    let validation =
        (address.firstName && /^.{1,70}$/.test(address.firstName)) &&
        (address.name && /^.{1,70}$/.test(address.name)) &&
        (eventId == 190322 || address.company && /^.{1,70}$/.test(address.company)) &&
        (address.code1 && /^.{1,70}$/.test(address.code1)) &&
        /^\S+@\S+\.\S+$/.test(address.email) &&
        (address.email == address.confirmEmail);

    return (
        <div className='container page-container'>
            <Header language={language} />
            <div className='text-center'>
                <p className='fw-bold fs-2 mb-1'>{resources?.LoginTitle}</p>
                {(eventId == 190322 || eventId == "190322") ?
                    <p className='fs-6'>
                        {resources?.FirstEventDateMitarbeiter}
                        <br></br>
                        {resources?.EventLocationMitarbeiter}
                    </p> :
                    <p className='fs-6'>
                        {isFirstEvent == true ? resources?.FirstEventDate : isFirstEvent == false ? resources?.SecondEventDate : ""}
                        <br></br>
                        {resources?.EventLocation}
                    </p>}
            </div>
            <div>
                <div className="mb-3">
                    <input className="form-control input" type="text" placeholder={resources?.Name} name='name' value={address.name} onChange={onInputChange} />
                </div>
                <div className="mb-3">
                    <input className="form-control input" type="text" placeholder={resources?.FirstName} name='firstName' value={address.firstName} onChange={onInputChange} />
                </div>
                {eventId == 190322 && <div className="mb-3">
                    <input className="form-control input" type="text" placeholder={resources?.Mobile} name='mobile' value={address.mobile} onChange={onInputChange} />
                </div>}
                {eventId != 190322 && <div className="mb-3">
                    <input className="form-control input" type="text" placeholder={resources?.Company} name='company' value={address.company} onChange={onInputChange} />
                </div>}
                <div className="mb-3">
                    <input className="form-control input" type="text" placeholder={resources?.Email} name='email' value={address.email} onChange={onInputChange} />
                </div>
                <div className="mb-3">
                    <input className="form-control input" type="text" placeholder={resources?.EmailConfirmation} name='confirmEmail' value={address.confirmEmail} onChange={onInputChange} />
                </div>
                <div className="row">
                    <div className="col-md-1 col-12">
                        {resources?.Menu}:
                    </div>
                    <div className="col-md-11 col-12 mb-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={address.code1 == resources?.Meat} onChange={() => setAddress({ ...address, code1: resources?.Meat })} />
                            <label class="form-check-label" for="flexCheckDefault">
                                {resources?.Meat}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={address.code1 == resources?.Vegetarian} onChange={() => setAddress({ ...address, code1: resources?.Vegetarian })} />
                            <label class="form-check-label" for="flexCheckDefault">
                                {resources?.Vegetarian}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={address.code1 == resources?.Vegan} onChange={() => setAddress({ ...address, code1: resources?.Vegan })} />
                            <label class="form-check-label" for="flexCheckDefault">
                                {resources?.Vegan}
                            </label>
                        </div>
                    </div>
                </div>
                {eventId != 190322 && <div className="mb-3">
                    <input className="form-control input" type="text" placeholder={resources?.Allergies} name='code2' value={address.code2} onChange={onInputChange} />
                </div>}
                {eventId == 190322 && <div className="row">
                    <div className="col-md-3 col-12">
                        {resources?.Journey}:
                    </div>
                    <div className="col-md-9 col-12 mb-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={address.code7 == resources?.Journey1} onChange={() => setAddress({ ...address, code7: resources?.Journey1 })} />
                            <label class="form-check-label" for="flexCheckDefault">
                                {resources?.Journey1}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={address.code7 == resources?.Journey2} onChange={() => setAddress({ ...address, code7: resources?.Journey2 })} />
                            <label class="form-check-label" for="flexCheckDefault">
                                {resources?.Journey2}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={address.code7 == resources?.Journey3} onChange={() => setAddress({ ...address, code7: resources?.Journey3 })} />
                            <label class="form-check-label" for="flexCheckDefault">
                                {resources?.Journey3}
                            </label>
                        </div>
                    </div>
                </div>}
                {eventId == 190322 && <div className="mb-3">
                    <select className="form-select input" type="text" name='code6' value={address.code6} onChange={onInputChange}>
                        <option value={resources?.PublicTransport}>{resources?.PublicTransport}</option>
                        <option value={resources?.PublicTransport1}>{resources?.PublicTransport1}</option>
                        <option value={resources?.PublicTransport2}>{resources?.PublicTransport2}</option>
                        <option value={resources?.PublicTransport3}>{resources?.PublicTransport3}</option>
                    </select>
                </div>}
                <div className="mb-3">
                    <textarea className="form-control input" type="text" placeholder={resources?.Remarks} name='code3' value={address.code3} onChange={onInputChange} />
                </div>
                <div className="mb-3">
                    <p>{resources?.NecessaryFields}</p>
                </div>
                <button className='button btn mb-5' onClick={onSubmit} disabled={!validation}>{resources?.Confirm}</button>
            </div>
            <Footer />
        </div>
    )
}

export default Address