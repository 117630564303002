import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

import Header from './components/Header'
import Footer from './components/Footer'

const Logout = () => {
    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language, orderId } = useParams();
    const [selectedEvent] = useState(sessionStorage.getItem("selectedEventId") ?? 0);

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div className='container page-container'>
            <Header />
            <div className='text-center'>
                <p className='fs-5'>{(selectedEvent == 190322 || selectedEvent == "190322") ? parse(resources?.LogoutConfirmationTextMitarbeiter ?? "") : parse(resources?.LogoutConfirmationText ?? "")}</p>
            </div>
            <Footer />
        </div>
    )
}

export default Logout